<template>
  <div>
    <!-- {{ application_settings.secure_e_sign.notify_through }} -->
    <!-- {{ application_settings.document_generation_settings.add_document_generated_id }}  -->

    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Application Settings</h3>
      </div>
      <el-form ref="form">
        <el-row class="sidemenu">
          <el-col :span="24">
            <div class="card-body">
              <div class="p-4">
                <h4 class="fs-6 text-dark fw-medium">
                  Default date field format
                </h4>
                <p>
                  Your selection will be applied to all your new documents and
                  templates.
                </p>
                <div>
                  <el-select v-model="application_settings.date">
                    <el-option
                      v-for="(date, index) in formats"
                      :key="index"
                      :value="date"
                      :label="$moment().format(date)"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="p-4">
                <h4 class="fs-6 text-dark fw-medium">Email Attachment</h4>
                <p>
                  When a document is completed, recipients will receive an
                  automated email with a PDF copy of the document attached.
                </p>
                <div class="mb-1">
                  <el-checkbox
                    v-model="application_settings.email_attachement.attach_pdf"
                  >
                    Attach a PDF of completed document to all
                    recipients</el-checkbox
                  >
                </div>
              </div>
            </div>
            <template v-if="this.isWorkspaceIsBusiness(getActiveWorkspace)">
              <el-divider class="m-0"></el-divider>
              <div class="card-body">
                <div class="p-4">
                  <h4 class="fs-6 text-dark fw-medium">Update entity data</h4>
                  <p>
                    If you used entity fields on document. You can update the
                    entity data by that document data.
                  </p>
                  <div class="mb-1">
                    <el-radio-group
                      v-model="application_settings.update_entity_data"
                    >
                      <el-radio :label="0"
                        >Update after document completion</el-radio
                      >
                      <el-radio :label="1"
                        >Update after user finishes the document</el-radio
                      >
                      <el-radio :label="2">Never update</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </template>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="p-4">
                <h4 class="fs-6 text-dark mb-1 fw-medium">
                  Document Signature
                </h4>

                <div class="mb-1">
                  <!-- <el-checkbox
                    v-model="
                      application_settings.document_signature.require_a_reason
                    "
                  >
                    Require a reason when a recipient declines to
                    sign</el-checkbox
                  > -->
                  <el-checkbox
                    v-model="
                      application_settings.document_signature
                        .allow_sender_auto_fill
                    "
                  >
                    Auto-complete sender if all fields filled</el-checkbox
                  >
                </div>

                <div class="mb-1">
                  <el-checkbox
                    v-model="
                      application_settings.document_signature
                        .show_document_settings_when_sent
                    "
                  >
                    Show document expiration settings when sending the
                    document</el-checkbox
                  >
                </div>
                <div class="mb-1">
                  <el-checkbox
                    v-model="
                      application_settings.document_signature
                        .show_mail_box_when_sent
                    "
                  >
                    Show mail box when sending the document</el-checkbox
                  >
                </div>

                <!-- <div class="mb-1">
                  <el-checkbox
                    v-model="
                      application_settings.document_signature.allow_recepients
                    "
                  >
                    Allow recipients sign on paper</el-checkbox
                  >
                </div>
                <div class="mb-0">
                  <el-checkbox
                    v-model="
                      application_settings.document_signature
                        .allow_recepient_to_change
                    "
                  >
                    Allow recipients to change the signing
                    responsibility</el-checkbox
                  >
                </div> -->
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="p-4">
                <h4 class="fs-6 text-dark fw-medium">
                  Document Security & Authentication
                </h4>
                <el-checkbox
                  class="mb-1"
                  v-model="
                    application_settings.secure_e_sign
                      .document_access_with_password
                  "
                >
                  Document Access with 'Password' Authentication</el-checkbox
                >
                <div class="mb-1">
                  <el-checkbox
                    v-model="
                      application_settings.secure_e_sign.allow_document_e_sign
                    "
                  >
                    Enable E-Signature 'OTP' Authentication</el-checkbox
                  >
                </div>

                <div
                  class="mb-1 ml-1"
                  v-if="
                    application_settings.secure_e_sign.allow_document_e_sign
                  "
                >
                  <el-checkbox-group
                    v-model="application_settings.secure_e_sign.notify_through"
                  >
                    <el-checkbox label="Send_Email">Send Email</el-checkbox>
                    <el-checkbox label="Send_Sms">Send Sms</el-checkbox>
                    <!-- <el-checkbox label="Send_Whatsapp">Send Whatsapp</el-checkbox>  -->
                  </el-checkbox-group>
                  <span
                    style="color: red"
                    v-if="
                      sendError &&
                      !this.application_settings.secure_e_sign.notify_through
                        .length
                    "
                  >
                    Please Select Atleast One Option
                  </span>
                </div>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="p-4">
                <h4 class="fs-6 text-dark fw-medium">
                  Document Generation Settings
                </h4>

                <div class="mb-1">
                  <el-checkbox
                    v-model="
                      application_settings.document_generation_settings
                        .add_document_generated_id
                    "
                  >
                    Add eSigns generated id</el-checkbox
                  >
                  <el-checkbox
                    v-model="
                      application_settings.document_generation_settings
                        .add_page_number
                    "
                  >
                    Add page numbers</el-checkbox
                  >
                  <el-checkbox
                    v-model="
                      application_settings.document_generation_settings
                        .add_page_info
                    "
                  >
                    Add page information
                  </el-checkbox>
                </div>
                <div
                  v-if="
                    application_settings.document_generation_settings
                      .add_page_info
                  "
                  class="mt-1"
                >
                  <div>
                    <label>Content: </label>
                    <el-radio-group
                      v-model="
                        application_settings.document_generation_settings
                          .page_info_type
                      "
                    >
                      <el-radio label="timestamp">Time Stamp</el-radio>
                      <el-radio label="text">Text</el-radio>
                    </el-radio-group>
                    <el-input
                      v-if="
                        application_settings.document_generation_settings
                          .page_info_type == 'text'
                      "
                      v-model="
                        application_settings.document_generation_settings
                          .page_info_text
                      "
                      placeholder="Enter your text here"
                    >
                    </el-input>
                  </div>
                  <div class="mt-1">
                    <label>Alignment: </label>
                    <el-radio-group
                      v-model="
                        application_settings.document_generation_settings
                          .page_info_alignment
                      "
                    >
                      <el-radio label="left">Left</el-radio>
                      <el-radio label="center">Center</el-radio>
                      <el-radio label="right">Right</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <p class="error" v-if="getError">
          {{ getError }}
        </p>
        <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button @click="goToDashboard">Cancel</el-button>
            <el-button type="primary" @click="onSubmit">Save Changes</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import settings from "@/constants/settings";

import PermissionsHelper from "@/mixins/permissionsHelper";
export default {
  name: "ApplicationSettings",
  data() {
    return {
      loading: false,
      sendError: false,
      application_settings: {
        date: "",
        email_attachement: {
          attach_pdf: true,
        },
        document_signature: {
          require_a_reason: true,
          allow_recepients: false,
          allow_recepient_to_change: false,
          show_document_settings_when_sent: true,
          show_mail_box_when_sent: true,
          allow_sender_auto_fill: false,
        },
        secure_e_sign: {
          document_access_with_password: true,
          allow_document_e_sign: true,
          notify_through: [],
        },

        update_entity_data: 0,
        document_generation_settings: {
          add_document_generated_id: true,
          add_page_number: true,
          add_page_info: false,
          page_info_type: "timestamp",
          page_info_text: "",
          page_info_alignment: "left",
        },
      },
      formats: [],
    };
  },
  computed: {
    ...mapGetters("settings", [
      "getApplicationSettings",
      "getUpdateApplicationSettingsStatus",
    ]),
    ...mapGetters("errors", ["getError"]),
  },
  mixins: [PermissionsHelper],
  async mounted() {
    this.loading = true;
    this.formats = settings.DATE_FORMATS;
    this.$store.commit("errors/reset", null, { root: true });
    await this.fetchApplicationSettings();
    this.setApplicationSettings();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchApplicationSettings: "settings/fetchApplicationSettings",
      updateApplicationSettings: "settings/updateApplicationSettings",
    }),
    setApplicationSettings() {
      if (this.getApplicationSettings && this.getApplicationSettings.data) {
        this.application_settings.date = this.getApplicationSettings.data.date;
        this.application_settings.email_attachement.attach_pdf =
          this.getApplicationSettings.data.email_attachement.attach_pdf;
        this.application_settings.document_signature.require_a_reason =
          this.getApplicationSettings.data.document_signature.require_a_reason;
        this.application_settings.document_signature.show_document_settings_when_sent =
          this.getApplicationSettings.data.document_signature.show_document_settings_when_sent;
        this.application_settings.document_signature.show_mail_box_when_sent =
          this.getApplicationSettings.data.document_signature.show_mail_box_when_sent;
        this.application_settings.document_signature.allow_recepients =
          this.getApplicationSettings.data.document_signature.allow_recepients;
        this.application_settings.document_signature.allow_recepient_to_change =
          this.getApplicationSettings.data.document_signature.allow_recepient_to_change;
        this.application_settings.secure_e_sign.allow_document_e_sign =
          this.getApplicationSettings.data.secure_e_sign.allow_document_e_sign;
        //document_access_with_password
        this.application_settings.secure_e_sign.document_access_with_password =
          this.getApplicationSettings.data.secure_e_sign.document_access_with_password;
        this.application_settings.document_generation_settings.add_document_generated_id =
          this.getApplicationSettings.data.document_generation_settings.add_document_generated_id;
        this.application_settings.document_generation_settings.add_page_number =
          this.getApplicationSettings.data.document_generation_settings.add_page_number;
        this.application_settings.document_generation_settings.add_page_info =
          this.getApplicationSettings.data.document_generation_settings.add_page_info;
        this.application_settings.document_generation_settings.page_info_type =
          this.getApplicationSettings.data.document_generation_settings.page_info_type;
        this.application_settings.document_generation_settings.page_info_text =
          this.getApplicationSettings.data.document_generation_settings.page_info_text;
        this.application_settings.document_generation_settings.page_info_alignment =
          this.getApplicationSettings.data.document_generation_settings.page_info_alignment;
        this.application_settings.document_signature.allow_sender_auto_fill =
          this.getApplicationSettings.data.document_signature.allow_sender_auto_fill;
        this.application_settings.update_entity_data =
          this.getApplicationSettings.data.update_entity_data;
        this.application_settings.secure_e_sign.notify_through =
          this.getApplicationSettings.data?.secure_e_sign.notify_through;
      }
    },
    async onSubmit() {
      try {
        this.loading = true;
        if (
          this.application_settings.secure_e_sign.allow_document_e_sign &&
          this.application_settings.secure_e_sign.notify_through.length < 1
        ) {
          this.sendError = true;
          this.loading = false;
        } else {
          await this.updateApplicationSettings({
            application_settings: this.application_settings,
          });
          if (this.getUpdateApplicationSettingsStatus) {
            this.successNotify("Settings updated successfully");
            await this.fetchApplicationSettings();
            this.setApplicationSettings();
          } else {
            this.errorNotify("Error in updating settings");
          }
          this.loading = false;
        }
      } catch (err) {
        console.log("onSubmit", err);
      }
    },
    goToDashboard() {
      this.setApplicationSettings();
    },
  },
  beforeDestroy() {
    this.$store.commit("settings/setApplicationSettings", null, {
      root: true,
    });
    this.$store.commit("settings/setUpdateApplicationSettingsStatus", null, {
      root: true,
    });
    this.$store.commit("errors/setError", null, {
      root: true,
    });
  },
};
</script>
<style lang="scss" scoped>
.sidemenu {
  height: 510px;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
</style>
